import * as React from "react"
import "./layout.css"
import hexImage from "../images/hex-background.svg"

const Layout = ({ children }) => {
  return (
    <div
      style={{
        background: `url(${hexImage}), linear-gradient(180deg, #071232, #202124)`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "darken",
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <main
        style={{
          flex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {children}
      </main>
      <footer
        style={{
          margin: "2rem 0.5rem 0 auto",
          fontSize: ".6rem",
        }}
      >
        © {new Date().getFullYear()} Phirtual, Inc.
      </footer>
    </div>
  )
}

export default Layout
